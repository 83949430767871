<template>
  <div class="zdd-create-com">
    <div class="zdd-create-com-container">
      <div class="zdd-create-com-container-item" @click="transitionTo('com')">
        <div class="zdd-create-com-container-item-img">
          <SvgIcon type="zdd_create_com" />
        </div>
        <div class="zdd-create-com-container-item-main">
          <div class="zdd-create-com-container-item-main__title">
            企业使用，去创建企业
          </div>
          <div class="zdd-create-com-container-item-main__description">
            <span class="zdd-create-com-container-item-main__description-item"
              >注册企业</span
            >
            <span class="zdd-create-com-container-item-main__description-item"
              >颁发证书</span
            >
            <span class="zdd-create-com-container-item-main__description-item"
              >查询档案</span
            >
          </div>
        </div>
      </div>
      <div class="zdd-create-com-container-item" @click="transitionTo('user')">
        <div class="zdd-create-com-container-item-img">
          <SvgIcon type="zdd_create_com_user" />
        </div>
        <div class="zdd-create-com-container-item-main">
          <div class="zdd-create-com-container-item-main__title">
            个人使用，暂不创建企业
          </div>
          <div class="zdd-create-com-container-item-main__description">
            <span class="zdd-create-com-container-item-main__description-item"
              >实名认证</span
            >
            <span class="zdd-create-com-container-item-main__description-item"
              >授权证书</span
            >
            <span class="zdd-create-com-container-item-main__description-item"
              >管理档案</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import myApis from '@/api/my'

  export default {
    name: 'CreateCom',
    methods: {
      async transitionTo(name) {
        await this.closeHomePageGuideVersion()

        if (name === 'com') {
          // 创建企业
          this.$router.push({
            name: 'CreateCompany',
          })
        } else if (name === 'user') {
          // 跳转首页
          this.$router.replace({
            name: 'ZddHome',
          })
        }
      },
      // 关闭
      async closeHomePageGuideVersion() {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '处理中',
            loadingType: 'spinner',
          })
          await myApis.closeHomePageGuideVersion()
          return true
        } catch (error) {
          console.error(error)
          return false
        } finally {
          if (loadingInstance) {
            loadingInstance.close()
          }
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .zdd-create-com {
    padding: 15px;
    background: #f3f3f3;
    &-container {
      background: @WHITE;
      padding: 20px 22px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      &-item {
        width: 100%;
        display: flex;
        border: 2px solid #398aff;
        border-radius: 4px;
        padding: 22px 15px;
        cursor: pointer;
        &-img {
          font-size: 44px;
        }
        &-main {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          &__title {
            margin-top: 2px;
            font-size: 15px;
            font-weight: 500;
          }
          &__description {
            margin-top: 5px;
            display: flex;
            &-item {
              font-size: 13px;
              color: #676767;
            }
            .zdd-create-com-container-item-main__description-item
              + .zdd-create-com-container-item-main__description-item {
              margin-left: 11px;
            }
          }
        }
      }
      .zdd-create-com-container-item + .zdd-create-com-container-item {
        margin-top: 15px;
      }
    }
  }
</style>
